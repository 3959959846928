.Livechat {
  text-align: center;
}

.Livechat-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Livechat-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Livechat-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Livechat-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.profileImage
{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #512DA8;
  font-size: 24px;
  color: #fff;
  text-align: center;
}

.iconLiveChat
{
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #512DA8;
  font-size: 24px;
  color: #fff;
  text-align: center;
}